<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Languages'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Languages Create'"
      :title="'Languages Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :placeholder="'name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">Seo Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'seo_name'"
              :label="'seo_name'"
              :type="'text'"
              :placeholder="'seo name'"
              :error-messages="validationErrors['seoName']"
              isBordered
            />
          </div>
          <p class="form-create__label">Lang Code</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'lang_code'"
              :label="'lang_code'"
              :type="'text'"
              :placeholder="'lang code'"
              :error-messages="validationErrors['langCode']"
              isBordered
            />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";

export default {
  name: "LangCreate",
  metaInfo: {
    title: "Languages Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        lang_code: "",
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
  },
  computed: {
    ...mapState("lang", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.LANG_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name,
        seo_name: this.formDataToSend.seo_name,
        lang_code: this.formDataToSend.lang_code,
      };

      this.$store.dispatch("lang/createLang", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.LANG_LIST.replace(":page", 1),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
